body {
  /* background-color: #101414; */
  font-family: "Josefin Sans", sans-serif;
  margin: 0px;
}

h1 {
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #101414;
}